import axios from 'axios'
// import { message } from 'ant-design-vue'

const apiAxios = axios.create({
  timeout: 600000,
  withCredentials: true
})
apiAxios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const { status } = error.response
    if (status === 401) {
    } else {
      if (error.response.config.url === '/api/user/register') {
        return error.response
      }
      if (status !== 200) {
        // message.error(error.response.data.content)
        return false
      }
    }
  }
)

const api = {
  axios,
  instance: apiAxios,
  install (Vue) {
    Vue.prototype.$api = api
  },
  setDefaults (options) {
    Object.keys(options).forEach(o => {
      apiAxios.defaults[o] = options[o]
    })
  },
  async request (method, url, data, config) {
    const res = await apiAxios.request({ ...config, method, url, data })
    return res
  },
  async get (url, config = null, data = null) {
    const res = await api.request('get', url, data, config)
    return res
  },
  // async BatchExportExcel (data) {
  //   return promise(axios.post('/api/workorder/0177199093768a8382ce7718773717dc/workorder_file?', data, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data'
  //     }
  //   }))
  // },
  async post (url, data, config = null) {
    const res = await api.request('post', url, data, config)
    return res
  },
  async put (url, data, config = null) {
    const res = await api.request('put', url, data, config)
    return res
  },
  async delete (url, data, config = null) {
    const res = await api.request('delete', url, data, config)
    return res
  }
}
export default api
