<template>
  <div>
    <Header />
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header/CaseHeader'
import Footer from '@/components/footer/Footer'
export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style>
</style>
