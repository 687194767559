<template>
  <div>
    <slot></slot>
  </div>
</template>
<script>
import base from '@/core/base.js'
export default {
  mixins: [base],
  created () {
    if (this.currenttitle) {
      this.currenttitle = this.currenttitle + '-省公共法律服务平台'
    }
    document.title = this.currenttitle || window.SITE.title
  },
  activated () {
    document.title = this.title + '-XX省公共法律服务平台' || window.SITE.title
  },
  data () {
    return {
      currenttitle: this.title
    }
  }
}
</script>
<style>
</style>
