import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Cookies from 'js-cookie'
import VueApexCharts from 'vue-apexcharts'
import echarts from 'echarts'
import Share from 'vue-social-share'
import 'vue-social-share/dist/client.css'
import { Button, Tabs, Input, Pagination, Col, Row, ConfigProvider, Radio, Icon, Steps, Timeline, FormModel, Divider, Select, Table, Card, Collapse, Avatar, Badge, Carousel, Dropdown, Switch, Modal, Cascader, List, DatePicker, Tag, Spin, message, Progress, Rate, Alert, Upload, Result, Checkbox, Popover, Empty, Menu, Affix } from 'ant-design-vue'
import './style/bootstrap.css'
import 'ant-design-vue/dist/antd.css'
import './style/index.less'

import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import moment from 'moment'
import Page from './components/base/Page'
import 'moment/locale/zh-cn'

// import dayjs from 'dayjs'
// import relativeTime from 'dayjs/plugin/relativeTime'
// import 'dayjs/locale/zh-cn'

moment.locale('zh-cn')

Vue.component(Button.name, Button)
Vue.component(Tabs.name, Tabs)
Vue.component(Carousel.name, Carousel)
Vue.component(Timeline.name, Timeline)
Vue.component(Dropdown.name, Dropdown)
Vue.component(Tabs.TabPane.name, Tabs.TabPane)
Vue.component(Input.name, Input)
Vue.component(Input.Group.name, Input.Group)
Vue.component(Input.Search.name, Input.Search)
Vue.component(Input.TextArea.name, Input.TextArea)
Vue.component(Input.Password.name, Input.Password)
Vue.component(Pagination.name, Pagination)
Vue.component(Col.name, Col)
Vue.component(Row.name, Row)
Vue.component(ConfigProvider.name, ConfigProvider)
Vue.component('Page', Page)
// 数据展示
Vue.component('apexchart', VueApexCharts)
Vue.use(Radio)
Vue.use(Icon)
Vue.use(Steps)
Vue.use(FormModel)
Vue.use(Divider)
Vue.use(Select)
Vue.use(Timeline)
Vue.use(Table)
Vue.use(Card)
Vue.use(Collapse)
Vue.use(Avatar)
Vue.use(Badge)
Vue.use(Switch)
Vue.use(Modal)
Vue.use(Cascader)
Vue.use(List)
Vue.use(DatePicker)
Vue.use(Tag)
Vue.use(Spin)
Vue.use(Progress)
Vue.use(Rate)
Vue.use(Alert)
Vue.use(Upload)
Vue.use(Result)
Vue.use(Checkbox)
Vue.use(Popover)
Vue.use(Empty)
Vue.use(Menu)
Vue.use(Affix)
Vue.use(Share)

Vue.config.productionTip = false
Vue.prototype.$message = message
Vue.prototype.$modal = Modal
Vue.prototype.$echarts = echarts
Vue.prototype.$Cookies = Cookies

// dayjs.locale('zh-cn')
// dayjs.extend(relativeTime)

// 封面、照片类的默认图片
const defaultCover = require('./assets/images/nopic.jpg')
const defaultPhoto = require('./assets/images/expert-defaule-pic.jpg')
const defaultPicture = require('./assets/images/default_org.jpg')
const defaultface = require('./assets/images/default_personnel.png')

Vue.mixin({
  methods: {
    moment,
    // 内容封面
    getPostCoverURL (post, path = 'post', proportion = 32) {
      const postURL = `${store.state.fileBaseURL}${path}/${post.post_id}?_file=cover&_fileId=${post.cover}&_mode=inline`
      return post.cover ? postURL : defaultCover
    },
    // 值班律师图片
    getDutyCoverURL (postid, path = 'duty', proportion = 32) {
      const postURL = `${store.state.fileBaseURL}${path}/${postid}?_file=lawer_photo&_mode=inline`
      return postURL
    },
    getPostFileURL (file, path = 'post') {
      const fileURL = `${store.state.fileBaseURL}${path}/${file.object_id}/post_attachment/${file.file_id}?_file=file&_mode=inline`
      return file.file && fileURL
    },
    // 专家照片
    getExpertPhotoURL (post, path = 'expert') {
      const postURL = `${store.state.fileBaseURL}${path}/${post.expert}?_file=photo&_mode=inline`
      return post.photo ? postURL : defaultPhoto
    },
    // 手机号码加密显示
    showPhone (oldphone) {
      if (oldphone) {
        var phone = oldphone.substr(0, 3) + '****' + oldphone.substr(7)
        return phone
      }
    },
    // 时间显示
    showTimeForpage (oldTime) {
      var time = moment(oldTime).format('YYYY-MM-DD HH:mm:ss')
      return time
    },
    // 人员姓名加密
    showPerson (oleName) {
      if (oleName) {
        var name = oleName.substr(0, 1) + '**'
        return name
      }
    },
    // 机构照片
    getOrgPhotoURL (post, cover, path = 'provider') {
      const postURL = `${store.state.fileBaseURL}${path}/${post}?_file=cover&_mode=inline`
      return cover ? postURL : defaultPicture
    },
    // 人员照片
    getPersonPhotoURL (post, face, path = 'person') {
      const postURL = `${store.state.fileBaseURL}${path}/${post}?_file=face&_mode=inline`
      return face ? postURL : defaultface
    },
    goLoginTip (go) {
      if (Cookies.get('userData') === '' || Cookies.get('userData') === undefined) {
        Modal.confirm({
          content: h => <div>当前操作需要登录后进行</div>,
          okText: '去登录',
          cancelText: '返回',
          onOk () {
            Cookies.set('gopath', go)
            router.push('/login')
          },
          onCancel () {
            if (history.length === 1) {
              router.push('/')
            } else {
              router.back(-1)
            }
          }
        })
      } else {
        router.push(go)
      }
    }
  }
})

new Vue({
  router,
  store,
  components: { App },
  template: '<a-config-provider :locale="zhCN"><App/></a-config-provider>',
  data () {
    return {
      zhCN
    }
  }
}).$mount('#app')
