<template>
  <div class="affix-toolbar">
    <a-affix :offset-top="50">
      <ul>
        <li>
          <span><a-icon type="home" />
            <router-link to="/" target="_blank" class="affix-toolbar-item">
              <div class="affix-toolbar-item">首页</div>
            </router-link>
          </span>
        </li>
        <li>
          <a-popover title="我要咨询" placement="left">
          <div class="icon-arrow-left"><a-icon type="customer-service" /></div>
            <div class="affix-toolbar-item">我要问</div>
          <template slot="content">
            <a href="https://www.renrenlv.com.cn/gsps/qa/t/qa_1_t.html?channelNo=gslzQ001" class="btn" target="_blank">智能咨询</a>
            <a href="https://www.renrenlv.com.cn/gsps/qa/t/qa_1_t.html?channelNo=gslzQ001" class="btn" target="_blank">在线咨询</a>
            <router-link to="/Introduce" class="btn" target="_blank">热线咨询</router-link>
            <router-link to="/adviceadd" class="btn" target="_blank">留言咨询</router-link>
          </template>
        </a-popover>
        </li>
        <li>
          <a-popover title="公共法律服务热线" placement="left">
            <div class="icon-arrow-left"><a-icon type="phone" /></div>
            <div class="affix-toolbar-item">统一热线</div>
            <template slot="content">
              <h2 class="text-center">12348</h2>
            </template>
          </a-popover>
        </li>
        <li>
          <a-popover title="我要办理" placement="left">
            <div class="icon-arrow-left"><a-icon type="history" /></div>
            <div class="affix-toolbar-item">我要办</div>
            <template slot="content">
              <router-link to="/lawassistance" class="btn" target="_blank">法律援助</router-link>
              <router-link to="/mediate" class="btn" target="_blank">人民调解</router-link>
              <router-link to="/appraisal" class="btn" target="_blank">司法鉴定</router-link>
              <router-link to="/servicehall" class="btn" target="_blank">更多服务</router-link>
            </template>
          </a-popover>
        </li>
        <li>
          <a-popover title="法律知识库" placement="left">
            <div class="icon-arrow-left"><a-icon type="file-search" /></div>
            <div class="affix-toolbar-item">我要查</div>
            <template slot="content">
              <a href="http://search.chinalaw.gov.cn/search2.html" target="_blank" class="btn">基本法律法规</a>
              <router-link to="/law_res/list" class="btn" target="_blank">司法行政法律法规</router-link>
              <router-link to="/casebase" class="btn" target="_blank">司法行政案例库</router-link>
            </template>
          </a-popover>
        </li>
        <li>
          <span>
          <router-link to="/news" target="_blank"><a-icon type="eye" />
            <div class="affix-toolbar-item">我要看</div></router-link>
          </span>
        </li>
        <li>
          <span>
          <a href="/my#/sign-in" target="_blank"><a-icon type="form" />
            <div class="affix-toolbar-item">投诉评价</div></a>
          </span>
        </li>
        <li>
          <a-popover title="智能工具" placement="left">
            <div class="icon-arrow-left"><a-icon type="robot" /></div>
            <div class="affix-toolbar-item">智能工具</div>
            <template slot="content">
              <a href="https://www.renrenlv.com.cn/gsps/calculator/t/calculatorList.html" target="_blank" class="btn">法律计算器</a>
              <a href="https://www.renrenlv.com.cn/gsps/law/t/lawDocList.html" target="_blank" class="btn">法律文书</a>
              <a href="https://www.renrenlv.com.cn/gsps/law/t/lawSuggDocList.html" target="_blank" class="btn">法律意见书</a>
              <a href="https://www.renrenlv.com.cn/gsps/sentence/problem/list/t/problemList.html" target="_blank" class="btn">类案分析</a>
            </template>
          </a-popover>
        </li>
        <li>
          <a-popover title="掌上服务" placement="left">
            <div class="icon-arrow-left"><a-icon type="qrcode" /></div>
            <div class="affix-toolbar-item">掌上服务</div>
            <template slot="content">
              <div class="text-center">
              <a-icon type="qrcode" :style="{ fontSize: '100px' }"/>
              </div>
            </template>
          </a-popover>
        </li>
        <li class="pb-3">
          <span><a-icon type="vertical-align-top" />
            <a href="javascript: scroll(0, 0)">
              <div class="affix-toolbar-item">TOP</div>
            </a>
          </span>
        </li>
      </ul>
    </a-affix>
  </div>
</template>
<script>
export default {
  name: 'AffixforRight',
  data () {
    return {
      upAndDownChange: true
    }
  }
}
</script>
