<template>
<div class="case">
<header class="header-areacase">
  <div class="container">
    <div class="row">
      <div class="col-lg-9 col-md-9 col-sm-9 col-9">
        <div class="logo-area p-4"><span id="logo-ajax" title="司法行政（法律服务）案例库"><img src="../../assets/alk/images/logo.png" alt="alt"></span></div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-3">
        <div class="float-right pt-5">
          <div class="head-right-top">
            <template>
              <div>
                <a-divider type="vertical" />
                <a href="#">简体</a>
                <a-divider type="vertical" />
                <a href="#">繁体</a>
                <a-divider type="vertical" />
                <a href="#">བོད་ཡིག</a>
              </div>
            </template>
            <div class="head-right-bottom pt-3 float-rig" v-if="loginStatus">
               <router-link to="/Login" target="_blank" class="public-people">登录</router-link>
              <router-link to="/Register" target="_blank" class="public-login">注册</router-link>
          </div>
            <div class="head-right-bottom pt-3 float-rig" v-if="!loginStatus">
              <a href="/my#/workorder" class="public-people">个人中心</a>
              <a href="javascript:void(0)" class="public-people" @click="exit">退出</a>
          </div>
            </div>
        </div>
      </div>
    </div>
  </div>
  </header>
  <nav class="navbar navbar-expand-sm">
    <div class="container case-head">
            <ul class="navbar-nav justify-content-center">
            <li class="nav-item"><router-link to="/" class="nav-link">首页</router-link></li>
              <li class="nav-item"><router-link to="/servicehall" class="nav-link" target="_blank">服务大厅</router-link></li>
              <li class="nav-item"><router-link to="/news" class="nav-link" target="_blank">服务动态</router-link></li>
              <li class="nav-item"><router-link to="/numberservice" class="nav-link" target="_blank">数说服务</router-link></li>
              <li class="nav-item active"><a-dropdown><a class="ant-dropdown-link nav-link" @click="e => e.preventDefault()">
                  法律知识库<a-icon type="caret-down"/>
                </a>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a href="http://search.chinalaw.gov.cn/search2.html" class="dropdown-item" target="_blank">基本法律法规</a>
                  </a-menu-item>
                  <a-menu-item>
                    <router-link to="/law_res/list" class="dropdown-item" target="_blank">司法行政法律法规</router-link>
                  </a-menu-item>
                  <a-menu-item>
                    <router-link to="/casebase" class="dropdown-item" target="_blank">司法行政案例库</router-link>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
              </li>
              <li class="nav-item"><a-dropdown>
                <a class="ant-dropdown-link nav-link" @click="e => e.preventDefault()">
                  法律工具<a-icon type="caret-down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a class="dropdown-item"  href="https://www.renrenlv.com.cn/gsps/calculator/t/calculatorList.html"
                       target="_blank">法律计算器</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a class="dropdown-item"  href="https://www.renrenlv.com.cn/gsps/law/t/lawDocList.html"
                       target="_blank">法律文书</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a class="dropdown-item"  href="https://www.renrenlv.com.cn/gsps/law/t/lawSuggDocList.html"
                       target="_blank">法律意见书</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a class="dropdown-item"  href="https://www.renrenlv.com.cn/gsps/sentence/problem/list/t/problemList.html"
                       target="_blank">类案分析</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
              </li>
              <li class="nav-item"><a-dropdown>
                <a class="ant-dropdown-link nav-link" @click="e => e.preventDefault()">
                  法务地图<a-icon type="caret-down" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item>
                     <router-link to="/legalmap" class="dropdown-item" target="_blank">地图模式</router-link>
                  </a-menu-item>
                  <a-menu-item>
                     <router-link to="/allorgperson" class="dropdown-item" target="_blank">列表模式</router-link>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
              </li>
            </ul>
    </div>
     </nav>
</div>
</template>
<script>
export default {
  name: 'caselay-header',
  data () {
    return {
      loginStatus: true
    }
  },
  created () {
    if (this.$Cookies.get('userData') !== '' && this.$Cookies.get('userData') !== undefined) {
      this.loginStatus = false
    }
  },
  methods: {
    login () {
      this.$router.push('/login')
    },
    register () {
      this.$router.push('/register')
    },
    exit () {
      this.$Cookies.remove('userData')
      this.$Cookies.remove('phone')
      this.$Cookies.remove('idcard_no')
      this.$Cookies.remove('user_name')
      this.$Cookies.remove('uid')
      history.go(0)
    }
  }
}
</script>
