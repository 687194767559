<template>
  <div>
    <Header />
    <AffixforRight />
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/header/Header'
import Footer from '@/components/footer/Footer'
import AffixforRight from '@/components/layout/AffixforRight'
export default {
  components: {
    Header,
    Footer,
    AffixforRight
  }
}
</script>
<style>
</style>
