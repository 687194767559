import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/components/layout/Layout'
import CaseLay from '@/components/layout/CaseLay'
Vue.use(VueRouter)

const routes = [
  {
    path: '/signin',
    name: 'signin',
    component: () => import(/* webpackChunkName: "signin" */ '../pages/Signin.vue')
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../pages/Home.vue'),
    meta: {
      index: 0
    }
  },
  {
    path: '/hotline',
    name: '热线页面',
    component: () => import(/* webpackChunkName: "hotline" */ '../pages/hotline/HotlineRegister.vue')
  },
  {
    path: '/case/',
    component: CaseLay,
    meta: {
      index: 2
    },
    children: [
      {
        path: '/casebase',
        name: '案例首页',
        component: () => import(/* webpackChunkName: "casebase" */ '../pages/case/Casebase.vue')
      },
      {
        path: 'list/:id',
        name: '案例列表',
        component: () => import(/* webpackChunkName: "list" */ '../pages/case/List.vue')
      },
      {
        path: 'detail/:id',
        name: '案例详情',
        component: () => import(/* webpackChunkName: "detail" */ '../pages/case/Info.vue')
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: 'legalaid',
        name: '法援',
        component: () => import(/* webpackChunkName: "legalaid" */ '../pages/business/LegalAid.vue')
      },
      {
        path: 'expert',
        name: '专家库首页',
        component: () => import(/* webpackChunkName: "expert" */'../pages/expert/ExpertIndex.vue')
      },
      {
        path: 'expert/detail/:id',
        name: '专家库详情',
        component: () => import(/* webpackChunkName: "expertDetail" */'../pages/expert/ExpertDetail.vue')
      },
      {
        path: 'expert/query/:typename',
        name: '专家库列表',
        component: () => import(/* webpackChunkName: "expertQuery" */'../pages/expert/ExpertQuery.vue')
      },
      {
        path: 'expert/search',
        name: '专家库搜索',
        component: () => import(/* webpackChunkName: "expertSearch" */'../pages/expert/ExpertSearch.vue')
      },
      {
        path: 'expert/add',
        name: '专家库录入',
        component: () => import(/* webpackChunkName: "expertAdd" */'../pages/expert/ExpertApply.vue')
      },
      {
        path: 'article/list/:id',
        name: '资讯列表',
        component: () => import(/* webpackChunkName: "articleList" */'../pages/article/List.vue')
      },
      {
        path: 'article/detail/:id',
        name: '资讯详情',
        component: () => import(/* webpackChunkName: "articleDetail" */'../pages/article/Article.vue')
      },
      {
        path: 'law_res/list/:id',
        name: '法律法规',
        component: () => import(/* webpackChunkName: "lawresListId" */'../pages/law_res/List.vue')
      },
      {
        path: 'law_res/list',
        name: '法律法规',
        component: () => import(/* webpackChunkName: "lawresList" */'../pages/law_res/List.vue')
      },
      {
        path: 'law_res/detail/:id',
        name: '法律法规详情',
        component: () => import(/* webpackChunkName: "lawresDetail" */'../pages/law_res/Info.vue')
      },
      {
        path: 'adviceadd',
        name: '留言咨询',
        component: () => import(/* webpackChunkName: "adviceadd" */'../pages/advice/Add.vue')
      },
      {
        path: 'adviceinfo/:id',
        name: '咨询详情',
        component: () => import(/* webpackChunkName: "adviceinfo" */'../pages/advice/Info.vue')
      },
      {
        path: 'legalmap',
        name: '法务地图',
        meta: {
          index: 6
        },
        component: () => import(/* webpackChunkName: "legalmap" */ '../pages/business/LegalMap.vue')
      },
      {
        path: 'lawyerserve',
        name: '律师服务',
        component: () => import(/* webpackChunkName: "lawyerserve" */ '../pages/business/LawyerServe.vue')
      },
      {
        path: '/notarizationServe',
        name: '公证',
        component: () => import(/* webpackChunkName: "notarizationServe" */ '../pages/business/NotarizationServe.vue')
      },
      {
        path: 'peoplemediate',
        name: '人民调解',
        component: () => import(/* webpackChunkName: "peoplemediate" */ '../pages/business/PeopleMediate.vue')
      },
      {
        path: 'forensicexpertise',
        name: '司法鉴定',
        component: () => import(/* webpackChunkName: "forensicexpertise" */ '../pages/business/ForensicExpertise.vue')
      },
      {
        path: 'arbitrationserve',
        name: '仲裁服务',
        component: () => import(/* webpackChunkName: "arbitrationserve" */ '../pages/business/ArbitrationServe.vue')
      },
      {
        path: 'basiclaw',
        name: '基层法律服务',
        component: () => import(/* webpackChunkName: "basiclaw" */ '../pages/business/BasicLaw.vue')
      },
      {
        path: 'lawexam',
        name: '法考',
        component: () => import(/* webpackChunkName: "lawexam" */ '../pages/business/LawExam.vue')
      },
      {
        path: 'orgdetails/:id',
        name: '机构详情',
        component: () => import(/* webpackChunkName: "orgdetails" */ '../pages/business/OrgDetails.vue')
      },
      {
        path: 'persondetails/:id',
        name: 'Evaluation详情',
        component: () => import(/* webpackChunkName: "persondetails" */ '../pages/business/PersonDetails.vue')
      },
      {
        path: '/mediate',
        name: '调解预约',
        component: () => import(/* webpackChunkName: "mediate" */ '../pages/apply/Mediate.vue')
      },
      {
        path: '/appraisal',
        name: '鉴定预约',
        component: () => import(/* webpackChunkName: "appraisal" */ '../pages/apply/Appraisal.vue')
      },
      {
        path: '/lawyer',
        name: '律师预约',
        component: () => import(/* webpackChunkName: "lawyer" */ '../pages/apply/Lawyer.vue')
      },
      {
        path: '/notarization',
        name: '公证预约',
        component: () => import(/* webpackChunkName: "notarization" */ '../pages/apply/Notarization.vue')
      },
      {
        path: '/news',
        name: '服务动态',
        meta: {
          index: 1
        },
        component: () => import(/* webpackChunkName: "news" */ '../pages/News.vue')
      },
      {
        path: '/servicehall',
        name: '服务大厅',
        meta: {
          index: 2
        },
        component: () => import(/* webpackChunkName: "servicehall" */ '../pages/Servicehall.vue')
      },
      {
        path: '/numberservice',
        name: '数说服务',
        component: () => import(/* webpackChunkName: "numberservice" */ '../pages/NumberService.vue'),
        meta: {
          index: 3
        }
      },
      {
        path: '/search',
        name: '搜索页面',
        component: () => import(/* webpackChunkName: "search" */ '../pages/Search.vue')
      },
      {
        path: '/globalcomplaint',
        name: '全局投诉',
        component: () => import(/* webpackChunkName: "globalcomplaint" */ '../pages/complaint/GlobalComplaint')
      },
      {
        path: '/login',
        name: '登录页面',
        component: () => import(/* webpackChunkName: "login" */ '../pages/login/Login.vue')
      },
      {
        path: '/register',
        name: '注册页面',
        component: () => import(/* webpackChunkName: "register" */ '../pages/register/Register.vue')
      },
      {
        path: '/detailsevaluation',
        name: '案件统计页面',
        component: () => import(/* webpackChunkName: "detailsevaluation" */ '../pages/evaluation/DetailsEvaluation.vue')
      },
      {
        path: '/evaluation',
        name: '满意度评价首页',
        component: () => import(/* webpackChunkName: "evaluation" */ '../pages/evaluation/Evaluation.vue')
      },
      {
        path: '/evaluationcommit',
        name: '满意度评价提交页面',
        component: () => import(/* webpackChunkName: "evaluationcommit" */ '../pages/evaluation/EvaluationCommit.vue')
      },
      {
        path: '/zan',
        name: '点赞页面',
        component: () => import(/* webpackChunkName: "zan" */ '../pages/evaluation/Zan.vue')
      },
      {
        path: '/arbitrationapply',
        name: '仲裁申请',
        component: () => import(/* webpackChunkName: "arbitrationapply" */ '../pages/apply/ArbitrationApply.vue')
      },
      {
        path: '/lawassistance',
        name: '法律援助办理',
        component: () => import(/* webpackChunkName: "lawassistance" */ '../pages/apply/LawAssistance.vue')
      },
      {
        path: '/commontsandnice',
        name: '机构人员评价',
        component: () => import(/* webpackChunkName: "commontsandnice" */ '../pages/commontsandnice/Global.vue')
      },
      {
        path: '/farmerstx',
        name: '农名工讨薪专题页',
        component: () => import(/* webpackChunkName: "farmerstx" */ '../pages/channel/Farmerstx.vue')
      },
      {
        path: '/veterans',
        name: '退伍军人专题页面',
        component: () => import(/* webpackChunkName: "veterans" */ '../pages/channel/Veterans.vue')
      },
      {
        path: '/volunteer/add',
        name: '志愿者录入',
        component: () => import(/* webpackChunkName: "volunteerAdd" */ '../pages/volunteer/VolunteerApply.vue')
      },
      {
        path: '/introduce',
        name: '热线介绍',
        component: () => import(/* webpackChunkName: "introduce" */ '../pages/hotline/Introduce')
      },
      {
        path: '/lawservice',
        name: '法援中心',
        component: () => import(/* webpackChunkName: "lawservice" */ '../pages/lawservicecore/lawService')
      },
      {
        path: '/peodemo',
        name: '人员详情demo',
        component: () => import(/* webpackChunkName: "peodemo" */ '../pages/demo/Peo')
      },
      {
        path: '/prodemo',
        name: '机构详情demo',
        component: () => import(/* webpackChunkName: "prodemo" */ '../pages/demo/Pro')
      },
      {
        path: '/torf',
        name: '提交页面',
        component: () => import(/* webpackChunkName: "torf" */ '../pages/expert/TorF.vue')
      },
      {
        path: '/basiclever',
        name: '基层法律服务',
        component: () => import(/* webpackChunkName: "basiclever" */ '../pages/apply/BasicLever.vue')
      },
      {
        path: '/allorgperson',
        name: '所有机构、人员',
        component: () => import(/* webpackChunkName: "allorgperson" */ '../pages/evaluation/AllOrgPerson.vue')
      },
      {
        path: '/mediatehandle',
        name: '调解办理',
        component: () => import(/* webpackChunkName: "mediatehandle" */ '../pages/apply/MediateHandle')
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
// router.beforeEach((to, form, next) => {
//   if (to.path !== '/legalaid' && to.path !== '/globalcomplaint' && to.path !== '/adviceadd') {
//     next()
//   } else {
//     if (getCookie('phone') !== '') {
//       next()
//     } else {
//       Modal.confirm({
//         content: h => <div>当前操作需要登录后进行</div>,
//         okText: '去登录',
//         cancelText: '返回',
//         onOk () {
//           // setCookie('gohistory', to.path)
//           next('/login')
//         },
//         onCancel () {
//           next(form.path)
//         }
//       })
//     }
//   }
// })

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
