<template>
  <footer class="footer-copyright">
    <div class="container">
    <div class="col-lg-6 col-md-12 col-xs-12 p-0 mt-3 float-left text-right">
      <span class="footer-copyright--block">主办单位：xx省司法厅</span>
      <span class="footer-copyright--block">运维保障：xx省司法厅</span>
      <span class="footer-copyright--block">版权所有：xx省司法厅</span>
    </div>
    <div class="col-lg-6 col-md-12 col-xs-12 p-0 mt-3 float-right text-left">
      <span class="footer-copyright--block">ICP备 1233455号</span>
      <span class="footer-copyright--block">公网安备 62010502001004号</span>
      <span class="footer-copyright--block">网站标识码 sm13000003</span>
    </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'layout-footer'
}
</script>

<style>
</style>
