<template>
  <header class="header" v-if="this.$route.name !== 'index' && this.$route.name !== '热线页面' && this.$route.name !== '注册页面' && this.$route.name !== '登录页面' ">
    <div class="container">
      <div class="row flex-wrapper">
        <div class="header-main">
          <h1 class="header-logo"><router-link to="/">公共法律服务网</router-link></h1>
          <div class="header-title"><a href="http://www.faxuan.net/">法宣在线</a></div>
        </div>
        <ul class="header-aside">
          <li><a href="javascript:void(0);" target="_blank" onMouseDown="ShowDetail()" accesskey="g" class="Accessibility">
            <a-icon type="eye" />无障碍阅读</a>
          </li>
          <li>
            <router-link to="/Register" target="_blank" class="public-login user-aside-title">注册</router-link>
          </li>
          <li>
            <router-link to="/Login" target="_blank" class="public-login user-aside-title">登陆</router-link>
          </li>
          <li>
            <router-link to="/Login" target="_blank" class="public-login user-aside-title">服务人员登陆</router-link>
          </li>
          <div class="header-user-center" v-if="!loginStatus">
            <li><a href="/my#/workorder" class="public-people user-aside-title">个人中心</a></li>
            <li class="border-0"><a href="javascript:void(0)" class="public-people user-aside-title" @click="exit">退出</a></li>
          </div>
        </ul>
      </div>
      <nav class="navbar navbar-expand-sm header-nav">
        <ul class="navbar-nav justify-content-center">
          <li :class="['nav-item',$route.meta.index == 0?'active':'']">
            <router-link to="/" class="nav-link">首页</router-link>
          </li>
          <li :class="['nav-item',$route.meta.index == 2?'active':'']">
            <a-dropdown>
              <router-link to="/servicehall" class="ant-dropdown-link nav-link" @click="e => e.preventDefault()">服务大厅
                <a-icon type="caret-down" />
              </router-link>
              <a-menu slot="overlay" class="col-md-12 nav-tile">
                <a-menu-item class="col-md-3 col-sm-3 col-xs-4">
                  <router-link to="/legalaid" class="dropdown-item" target="_blank">
                    <div class="service-icon"><i class="icon-flyz"></i></div>
                    <span class="nav-tile-name">法律援助</span> </router-link>
                </a-menu-item>
                <a-menu-item class="col-md-3 col-sm-3 col-xs-4">
                  <router-link to="/notarizationServe" class="dropdown-item" target="_blank">
                    <div class="service-icon"><i class="icon-gzfw"></i></div>
                    <span class="nav-tile-name">公证服务</span> </router-link>
                </a-menu-item>
                <a-menu-item class="col-md-3 col-sm-3 col-xs-4">
                  <router-link to="/forensicExpertise" class="dropdown-item" target="_blank">
                    <div class="service-icon"><i class="icon-sfjd"></i></div>
                    <span class="nav-tile-name">司法鉴定</span> </router-link>
                </a-menu-item>
                <a-menu-item class="col-md-3 col-sm-3 col-xs-4">
                  <router-link to="/peoplemediate" class="dropdown-item" target="_blank">
                    <div class="service-icon"><i class="icon-rmtj"></i> </div>
                    <span class="nav-tile-name">人民调解</span> </router-link>
                </a-menu-item>
                <a-menu-item class="col-md-3 col-sm-3 col-xs-4">
                  <router-link to="/lawyerserve" class="dropdown-item" target="_blank">
                    <div class="service-icon"><i class="icon-lvshi"></i> </div>
                    <span class="nav-tile-name">律师服务</span> </router-link>
                </a-menu-item>
                <a-menu-item class="col-md-3 col-sm-3 col-xs-4">
                  <router-link to="/arbitrationServe" class="dropdown-item" target="_blank">
                    <div class="service-icon"><i class="icon-zcfw"></i></div>
                    <span class="nav-tile-name">仲裁服务</span> </router-link>
                </a-menu-item>
                <a-menu-item class="col-md-3 col-sm-3 col-xs-4">
                  <router-link to="/lawexam" class="dropdown-item" target="_blank">
                    <div class="service-icon"><i class="icon-fakao"></i></div>
                    <span class="nav-tile-name">法考服务</span> </router-link>
                </a-menu-item>
                <a-menu-item class="col-md-3 col-sm-3 col-xs-4">
                  <router-link to="/basicLaw" class="dropdown-item" target="_blank">
                    <div class="service-icon"><i class="icon-jcflfw"></i></div>
                    <span class="nav-tile-name">基层法律服务</span> </router-link>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </li>
          <li :class="['nav-item',$route.meta.index == 1?'active':'']">
            <router-link to="/news" class="nav-link" target="_blank">服务动态</router-link>
          </li>
          <li :class="['nav-item',$route.meta.index == 3?'active':'']">
            <router-link to="/numberservice" class="nav-link" target="_blank">数说服务</router-link>
          </li>
          <li :class="['nav-item',$route.meta.index == 4?'active':'']">
            <a-dropdown> <a class="ant-dropdown-link nav-link" @click="e => e.preventDefault()">法律知识库
              <a-icon type="caret-down" />
            </a>
              <a-menu slot="overlay">
                <a-menu-item> <a href="http://search.chinalaw.gov.cn/search2.html" class="dropdown-item" target="_blank">基本法律法规</a> </a-menu-item>
                <a-menu-item>
                  <router-link to="/law_res/list" class="dropdown-item" target="_blank">司法行政法律法规</router-link>
                </a-menu-item>
                <a-menu-item>
                  <router-link to="/casebase" class="dropdown-item" target="_blank">司法行政案例库</router-link>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </li>
          <li :class="['nav-item',$route.meta.index == 5?'active':'']">
            <a-dropdown> <a class="ant-dropdown-link nav-link" @click="e => e.preventDefault()"> 法律工具
              <a-icon type="caret-down" />
            </a>
              <a-menu slot="overlay">
                <a-menu-item> <a class="dropdown-item"  href="https://www.renrenlv.com.cn/gsps/calculator/t/calculatorList.html"
                                 target="_blank">法律计算器</a> </a-menu-item>
                <a-menu-item> <a class="dropdown-item"  href="https://www.renrenlv.com.cn/gsps/law/t/lawDocList.html"
                                 target="_blank">法律文书</a> </a-menu-item>
                <a-menu-item> <a class="dropdown-item"  href="https://www.renrenlv.com.cn/gsps/law/t/lawSuggDocList.html"
                                 target="_blank">法律意见书</a> </a-menu-item>
                <a-menu-item> <a class="dropdown-item"  href="https://www.renrenlv.com.cn/gsps/sentence/problem/list/t/problemList.html"
                                 target="_blank">类案分析</a> </a-menu-item>
              </a-menu>
            </a-dropdown>
          </li>
          <li :class="['nav-item',$route.meta.index == 6?'active':'']">
            <a-dropdown> <a class="ant-dropdown-link nav-link" @click="e => e.preventDefault()"> 法务地图
              <a-icon type="caret-down" />
            </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <router-link to="/legalmap" class="dropdown-item" target="_blank">地图模式</router-link>
                </a-menu-item>
                <a-menu-item>
                  <router-link to="/allorgperson" class="dropdown-item" target="_blank">列表模式</router-link>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </li>
        </ul>
        <form class="header-search">
          <input type="text" @keyup.enter="search" class="header-search-input" placeholder="请输入关键字查询法律问题">
          <span class="input-group-addon" @click="search"><a-icon type="search" /></span>
        </form>
      </nav>
    </div>
  </header>
</template>
<script>
export default {
  name: 'layout-header',
  data () {
    return {
      loginStatus: true
    }
  },
  methods: {
    login () {
      this.$router.push('/login')
    },
    register () {
      this.$router.push('/register')
    },
    search () {
      if (this.keyword.length > 0) {
        this.$router.push('/search?type=1&keyword=' + encodeURI(this.keyword))
      } else {
      }
    },
    exit () {
      this.$Cookies.remove('userData')
      this.$Cookies.remove('phone')
      this.$Cookies.remove('idcard_no')
      this.$Cookies.remove('user_name')
      this.$Cookies.remove('uid')
      history.go(0)
    }
  },
  created () {
    if (this.$Cookies.get('userData') !== '' && this.$Cookies.get('userData') !== undefined) {
      this.loginStatus = false
    }
  },
  watch: {
    $route: function (to, from) {
      // 执行数据更新查询
      if (this.$Cookies.get('userData') !== '' && this.$Cookies.get('userData') !== undefined) {
        this.loginStatus = false
      }
    }
  }
}
</script>
<style>
</style>
