// 带有 slot 标识的，表示此处可分发插槽
export default {
  props: {
    // 头部
    header: {
      type: String // slot
    },
    // 额外内容，右上角操作区域，例如查看更多等
    extra: {
      type: String // slot
    },
    // 底部
    footer: {
      type: String // slot
    },
    // 自定义 class 类名
    className: {
      type: String
    },
    // 自定义标题区域样式类名
    headClass: {
      type: String
    },
    // 内容区域自定义样式类名
    bodyClass: {
      type: String
    },
    // 自定义标题区域样式
    headStyle: {
      type: Object,
      default: function () {
        return {}
      }
    },
    // 内容区域自定义样式
    bodyStyle: {
      type: Object,
      default: function () {
        return {}
      }
    },
    // 是否有边框
    bordered: {
      type: Boolean,
      default: false
    },
    // 封面
    cover: {
      type: String
    },
    // 图标
    icon: {
      type: String
    },
    // 标题
    title: {
      type: String // slot
    },
    // 副标题
    subTitle: {
      type: String // slot
    },
    // 描述
    description: {
      type: String // slot
    },
    // 内容
    content: {
      type: String // slot
    },
    // 类型
    type: {
      type: String
    },
    // 尺寸
    size: {
      type: String,
      default: 'default',
      validator: function (value) {
        return ['default', 'middle', 'small'].indexOf(value) !== -1
      }
    },
    // 列
    column: {
      type: [Number, String]
    },
    // 是否可点击
    disabled: {
      type: Boolean,
      default: false
    },
    // 输入框标签
    label: {
      type: String
    },
    // 值
    value: {
      type: null
    },
    // 占位符
    placeholder: {
      type: String
    },
    // 数据对象
    post: {
      type: Object
    },
    // 创建时间
    created: {
      type: Number
    },
    // 链接
    link: {
      type: String
    },
    // 手机号
    phone: {
      type: String
    },
    // 引用数组
    refObj: {
      type: Array
    },
    // 机构Id
    orgId: {
      type: String
    },
    // 人员Id
    personId: {
      typr: String
    },
    // 人员头像
    face: {
      typr: String
    },
    // 咨询链接
    url: {
      typr: String
    }
  }
}
