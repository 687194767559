import Vue from 'vue'
import Vuex from 'vuex'
import api from './api'

api.setDefaults({
  baseURL: 'https://strh.faxuan.net/gs12348'
})
Vue.use(api)

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    fileBaseURL: '/gs12348/api/'
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
